import React from "react";
import CustomBtn from "../common/CustomBtn";

function ClassBtn({selectedRegion,selectedClass, handleClassBtn}){
    let numButtons;
    switch (selectedRegion[0]) {
        case '서울':
            numButtons = 8;
            break;
        case '구미':
        case '부울경': 
            numButtons = 2;
            break;
        default:
            numButtons = 3;
            break;
    }
  
    return(
        <>
        {Array.from({ length: numButtons }, (_, index) => (
          <CustomBtn
            key={index}
            text={`${index + 1}반`}
            btncolor={index === selectedClass ? "green" : "yellow"}
            onClick={() => handleClassBtn(index)}
          />
        ))}
        
        </>
    )
}

export default ClassBtn;