import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  yellow,
  green,
  red,
  blue,
  pink,
  purple,
  deepOrange,
  blueGrey,
  indigo,
  lightBlue,
  grey,
} from "@mui/material/colors";

// 함수 컴포넌트 내에서 색상 설정 로직 작성
export default function CustomBtn({ text, btncolor = "yellow", ...props }) {
  const [myTheme, setMyTheme] = useState(yellow);

  // btncolor에 따라 myTheme 설정
  React.useEffect(() => {
    switch (btncolor) {
      case "yellow":
        setMyTheme(yellow);
        break;
      case "green":
        setMyTheme(green);
        break;
      case "red":
        setMyTheme(red);
        break;
      case "blue":
        setMyTheme(blue);
        break;
      case "pink":
        setMyTheme(pink);
        break;
      case "purple":
        setMyTheme(purple);
        break;
      case "deepOrange":
        setMyTheme(deepOrange);
        break;
      case "indigo":
        setMyTheme(indigo);
        break;
      case "blueGrey":
        setMyTheme(blueGrey);
        break;
      case "lightBlue":
        setMyTheme(lightBlue);
        break;
      case "grey":
        setMyTheme(grey);
        break;
      default:
        setMyTheme(yellow);
    }
  }, [btncolor]);

  // ColorButton styled 컴포넌트 정의
  const ColorButton = styled(Button)(({ theme }) => ({
    fontFamily: "Pretendard",
    color: theme.palette.getContrastText(myTheme[700]),
    backgroundColor: myTheme[700],
    "&:hover": {
      backgroundColor: myTheme[900],
    },
  }));

  return (
    <ColorButton variant="contained" id="customBtn" {...props}>
      {text}
    </ColorButton>
  );
}
