import React from "react";
import { RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';



function ChapterRadioGroup({selectedChapter, handleChapterChange}){
    const chapters = [['공통', 'i'], ['특화','j'], ['자율', 'k']];
    
    return(
      <RadioGroup row value={selectedChapter} onChange={handleChapterChange}>
        {chapters.map(([chapterName, chapterCode], index) => (
        <FormControlLabel
          key={index}
          value={chapterCode}
          control={<Radio />}
          label={
            <Typography style={{ fontFamily: 'Pretendard', fontSize: '14px' }}>
                {chapterName}
            </Typography>
          }
          labelPlacement="bottom"
        />
      ))}
    </RadioGroup>
        
    )
}

export default ChapterRadioGroup;