import React from "react";
import CustomBtn from "../common/CustomBtn";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

function TeamBtns({
  numButtons,
  selectedClass,
  selectedRegion,
  handleTeamBtn,
  handleGerrit,
  handleJira,
}) {
  return (
    <>
      {Array.from({ length: numButtons }, (_, index) => (
        <Box
          key={`section-${index}`} // 고유한 key를 Box에 설정
          component="section"
          sx={{ p: 2, border: "7px dashed lightgreen" }}
        >
          <Stack spacing={2}>
            <CustomBtn
              key={`team-${index}`} // 고유한 key를 설정
              text={`${selectedRegion[1]}${selectedClass + 1}${(index + 1)
                .toString()
                .padStart(2, "0")}`}
              btncolor={"pink"}
              onClick={() => handleTeamBtn(index)}
            />

            <CustomBtn
              key={`gerrit-${index}`} // 고유한 key를 설정
              text={`gerrit`}
              btncolor={"deepOrange"}
              onClick={() => handleGerrit(index)}
            />

            <CustomBtn
              key={`jira-${index}`} // 고유한 key를 설정
              text={`Jira`}
              btncolor={"lightBlue"}
              onClick={() => handleJira(index)}
            />
          </Stack>
        </Box>
      ))}
    </>
  );
}

export default TeamBtns;
